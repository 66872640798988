import ProgramList from "@components/List/ProgramList"
import Text from "lib/components/Text"
import Pagination from "lib/components/Pagination"
import { LIMIT_PER_PAGE } from "constants/common"
import Dropdown from "lib/components/Dropdown"

interface CategoryListDesktopProps {
  isLoading: boolean
  categorySlug: string,
  t,
  sectionName: string,
  sectionDescription: string,
  activeSortLabel: string,
  sortList,
  handleChangeSort,
  dataPrograms,
  totalProgram: number,
  page: number,
  handleChangePage,
}

function CategoryListDesktop({
  isLoading,
  categorySlug,
  t,
  sectionName,
  sectionDescription,
  activeSortLabel,
  sortList,
  handleChangeSort,
  dataPrograms,
  totalProgram,
  page,
  handleChangePage,
}: CategoryListDesktopProps) {

  function showingPageFrom() {
    let resultPageFrom = 0
    if (page === 1 || !page) {
      resultPageFrom = 1
    } else {
      resultPageFrom = page * LIMIT_PER_PAGE - (LIMIT_PER_PAGE || totalProgram)
    }
    return resultPageFrom
  }

  function showingPageTo() {
    let resultPageTo = 0
    if (totalProgram < LIMIT_PER_PAGE) {
      resultPageTo = (page || 1 * LIMIT_PER_PAGE) - (LIMIT_PER_PAGE - totalProgram)
    } else if ((page || 1) * LIMIT_PER_PAGE > totalProgram) {
      resultPageTo = totalProgram
    } else {
      resultPageTo = (page || 1) * LIMIT_PER_PAGE
    }
    return resultPageTo
  }

  return (
    <div className="relative px-4 sm:px-0 -mt-8 sm:mt-0 sm:pt-10">
      <div className="container mx-auto">
        <div className="my-6 grid grid-cols-1 items-center sm:grid-cols-12 lg:my-4">
          <div className="col-span-7 justify-self-start">
            <h1 className="xl:text-xl 2xl:text-2xl font-semibold leading-8 text-gray-900">
              {categorySlug === "all"
                ? t("all_training_from_selected_category")
                : sectionName}
            </h1>
            <Text size="small" customClass="leading-5" weight="medium" color="muted">
              {categorySlug === "all" ? t("we_have_many_training_here") : sectionDescription}
            </Text>
          </div>
          <div className="col-span-5 hidden  z-10 flex-col items-center justify-end gap-2 md:flex-row lg:flex">
            <Dropdown
              title={
                <div className="flex flex-row items-center gap-2">
                  <Text
                    size="small"
                    customClass="leading-5"
                    weight="medium"
                    color="gray-darkest">
                    {`  ${t("sort_by")} : ${t(`${activeSortLabel}`)}`}
                  </Text>
                </div>
              }
              iconColor="black">
              <ul className="list-none overflow-hidden rounded px-4 py-2">
                {sortList
                  .filter((item) => !!item.show)
                  .map((item) => (
                    <li
                      key={item.label}
                      className="cursor-pointer py-2"
                      data-sort={item.key}
                      onClick={handleChangeSort}>
                      <Text>{t(item.label)}</Text>
                    </li>
                  ))}
              </ul>
            </Dropdown>
          </div>
        </div>
        <div className="mt-10">
          <ProgramList
            sectionName={sectionName}
            programs={dataPrograms}
            loading={isLoading}
          />
        </div>
        <div className="mt-12" />
        {totalProgram > LIMIT_PER_PAGE && (
          <div
            className={`flex flex-col items-center lg:flex-row ${
              totalProgram > 0 && "border-t"
            }  mt-4 justify-between`}>
            <div className="lg:order-0 order-1">
              {totalProgram > 0 && (
                <div className="flex items-center justify-end lg:mt-0 lg:justify-start">
                  <Text weight="medium" size="extra-small">
                    {t("showing")} {showingPageFrom()} {t("to")} {showingPageTo()} {t("of")}{" "}
                    {totalProgram}
                  </Text>
                </div>
              )}
            </div>
            <div className="order-0 lg:order-1">
              {totalProgram > LIMIT_PER_PAGE && (
                <Pagination
                  limit={LIMIT_PER_PAGE}
                  totalData={totalProgram}
                  activePage={page || 1}
                  onChange={handleChangePage}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default CategoryListDesktop
