import { useContext, useEffect, useRef, useState, Ref } from "react"
import router from "next/router"

import { LanguageContext } from "context/LanguageContext"

import CardCategories from "@components/shared-components/Card/CardCategoriesFilter"
import WrapperAnimateRoute from "@components/shared-components/WrapperAnimate/WrapperAnimateShow"
import Badge from "@components/shared-components/ui/Badge"
import { PropsClassType } from "@components/shared-components/Modal/ModalAllFIlter"
import { capitalize, generateTrainingDate } from "lib/helpers"
import Icon from "lib/components/Icons"
import SelectWithSearch from "lib/components/SelectOption/withSearch"
import Text from "lib/components/Text"
import Checkbox from "lib/components/Checkbox"
import useQueryParams from "hooks/useQueryParams"
import Button from "lib/components/Button"

import { keySelectCategory } from "lib/components/CategorySelector"
import useSWR from "swr"
import { GetCategoriesQuery } from "@graphql/program/getCategories"
import fetcher from "lib/fetcher"
import CardPriceFilter from "../../Card/CardPriceFilter"
import CardDateFilter from "../../Card/CardDateFilter"

interface Props {
  resetCategories?: () => void
  applyFilterCategories?: (event) => void
  resetFilterDate?: () => void
  applyFilterDate?: (event) => void
  handleSubmitFilterPrices?: (event, secEvent) => void
  handleResetPrice?: () => void
  filterSelectOptionCategory?: (slug: string) => void
  applyFilterType?: (value) => void
}

interface SelectCategoriesProps {
  categoriesRef: Ref<HTMLDivElement>
  selectedCategories: Array<{
    category_name: string
  }>
  handleCardCategory: () => void
  isCardCategoryOpen: boolean
  applyFilterCategories: (event) => void
  categories: string
  resetCategories?: () => void
  titleCategories: string
}

function ClassType({ onChange, selectedByType, resetFilter, applyFilter }: PropsClassType) {
  const { useTranslation: t } = useContext(LanguageContext)
  return (
    <div className="flex flex-col space-y-4 py-4 px-4 bg-white shadow-search rounded-lg mt-4">
      {/* Filter class type */}
      <div>
        <Text weight="bold" customClass="block" size="small">
          {t("class_type")}
        </Text>
        <div className="flex flex-col w-full gap-y-4 pt-3">
          <Checkbox
            label="In-house"
            onChange={onChange}
            value="inhouse"
            checked={selectedByType?.includes("inhouse")}
          />
          <Checkbox
            label="Public"
            onChange={onChange}
            value="public"
            checked={selectedByType?.includes("public")}
          />
          {/* <Checkbox
            label="Bootcamp"
            onChange={onChange}
            value="bootcamp"
            checked={selectedByType?.includes("bootcamp")}
          /> */}
        </div>
      </div>

      {/* footer */}
      <div className="mt-6 hidden flex-row items-center border-t px-2 pt-4 lg:flex">
        <Button
          variant="text"
          color="white"
          colorText="muted"
          fontWeight="semibold"
          onClick={resetFilter}
          customClass="w-10">
          {t("reset")}
        </Button>
        <div className="flex w-full items-end justify-end">
          <Button
            variant="contained"
            color="primary"
            rounded="large"
            onClick={applyFilter}
            customClass="flex flex-wrap justify-center w-28 px-4 py-2.5 hover:bg-blue-600 font-medium text-base text-white border border-blue-500 rounded-md shadow-button">
            {t("apply")}
          </Button>
        </div>
      </div>
    </div>
  )
}

function SelectCategories({
  categoriesRef,
  selectedCategories,
  handleCardCategory,
  isCardCategoryOpen,
  applyFilterCategories,
  categories,
  resetCategories,
  titleCategories,
}: SelectCategoriesProps) {
  return <div
    ref={categoriesRef}
    className={`relative border ${
      selectedCategories?.length > 0 ? "border-blue-700" : "border-gray-200"
    } font-heading group flex-auto items-center rounded-lg bg-white text-sm font-medium`}>
    <div
      tabIndex={0}
      role="button"
      onClick={handleCardCategory}
      className="relative flex h-11 w-full items-center justify-between px-5 ">
      {selectedCategories.length <= 0 ? (
        <Text
          color={categories?.split(",").length > 0 ? "gray" : "muted"}
          weight="medium"
          size="small">
          {titleCategories}
        </Text>
      ) : (
        <div className="flex gap-4">
          {selectedCategories.map((category, index) => {
            if (index < 2) {
              return (
                <Badge
                  key={index}
                  label={category.category_name}
                  bgColor="bg-primary"
                  customClass="rounded"
                  color="text-white"
                />
              )
            }
            return false
          })}
        </div>
      )}
      {selectedCategories.length > 2 ? (
        <div className="flex h-7 w-8 items-center justify-center rounded-lg bg-blue-100 p-2">
          <Text size="extra-small" color="gray">{`+${selectedCategories?.length - 2}`}</Text>
        </div>
      ) : (
        <Icon
          iconName="arrowBottom"
          styles={`transition ${isCardCategoryOpen ? "rotate-180" : "rotate-0"}`}
        />
      )}
    </div>
    <WrapperAnimateRoute
      type="show"
      isLoading={!isCardCategoryOpen}
      className={` absolute -left-4 z-10   h-px-533 w-px-636 ${
        isCardCategoryOpen ? "top-9" : "hidden"
      }`}>
      <div ref={categoriesRef} className="h-px-533 w-px-636">
        <CardCategories />
      </div>
    </WrapperAnimateRoute>
  </div>
}

function ProgramsFilter({
  resetCategories,
  applyFilterCategories,
  resetFilterDate,
  applyFilterDate,
  handleSubmitFilterPrices,
  handleResetPrice,
  filterSelectOptionCategory,
  applyFilterType,
}: Props) {
  const queries = useQueryParams()
  const categorySlugQuery = queries.get("category")
  const { category: categorySlug } = router.query
  const selectedByType = queries.get("type")

  const { useTranslation: t, language } = useContext(LanguageContext)
  const { data: allCategories } = useSWR<GetCategoriesQuery>("/api/categories", fetcher)
  const { categories, date_range, min_price, max_price } = router.query
  const [isCardCategoryOpen, setCardCategory] = useState(false)
  const [isCardDateOpen, setCardDate] = useState(false)
  const [isCardPriceOpen, setCardPrice] = useState(false)
  const [showTypeProgram, setShowTypeProgram] = useState(false)
  const [categoryAllData, setCategoryAllData] = useState([])
  const [selectedCategory, setSelectedCategory] = useState([])
  const [classTypes, setClassType] = useState<string[]>(["inhouse", "public"])

  const categoriesRef = useRef<HTMLDivElement>()
  const dateRef = useRef<HTMLDivElement>()
  const priceRef = useRef<HTMLDivElement>()
  const typeRef = useRef<HTMLDivElement>()

  const setWhichToOpen = (category: boolean, date: boolean, price: boolean, type: boolean) => {
    setCardCategory(category)
    setCardDate(date)
    setCardPrice(price)
    setShowTypeProgram(type)
  }

  useEffect(() => {
    if (categorySlugQuery !== "all") {
      let keySelectorCategory: keySelectCategory = "category_name"

      if (language === "ID") {
        keySelectorCategory = "category_name_indo"
      }

      const categoryProgram = allCategories ? allCategories.program_category : []
      const allDataCategory = categoryProgram
        .filter((category) => category.categories.length === 0)
        .map((category) => ({
          label: category[keySelectorCategory] || category.category_name,
          value: category.slug,
          description: category.category_description,
        }))
      setSelectedCategory(allDataCategory.filter(it => it.value === categorySlug))
      setCategoryAllData([{ value: "all", label: t("all_categories") }, ...allDataCategory])
    }

    return () => {
      setCategoryAllData([])
    }
  }, [categorySlugQuery, allCategories, language, t, categorySlug])  

  useEffect(() => {
    if (selectedByType) {
      const dataSelectedType = selectedByType.split(",")
      setClassType(dataSelectedType)
    }
  }, [selectedByType])

  const handleClassTypes = (e) => {
    setClassType(() => {
      if (e.target.checked) {
        if (classTypes?.length > 0) {
          return [...classTypes.filter((val) => val !== e.target.value), e.target.value]
        }
        return [e.target.value]
      }
      return [...classTypes.filter((val) => val !== e.target.value)]
    })
  }

  const handleCardDate = () => {
    setWhichToOpen(false, !isCardDateOpen, false, false)
  }

  const handleCardPrice = () => {
    setWhichToOpen(false, false, !isCardPriceOpen, false)
  }

  const handleShowProgramType = () => {
    setWhichToOpen(false, false, false, !showTypeProgram)
  }

  const handleCardCategory = () => {
    setWhichToOpen(!isCardCategoryOpen, false, false, false)
  }

  const handleClickOutside = (e) => {
    if (!categoriesRef.current.contains(e.target)) {
      setCardCategory(false)
    }
    if (!dateRef.current.contains(e.target)) {
      setCardDate(false)
    }
    if (!priceRef.current.contains(e.target)) {
      setCardPrice(false)
    }
    if (!typeRef.current.contains(e.target)) {
      setShowTypeProgram(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => document.removeEventListener("mousedown", handleClickOutside)
  })

  useEffect(() => {
    setWhichToOpen(false, false, false, false)
  }, [router.query])

  function showPrice(minPrice, maxPrice) {
    let minPriceValue = ""
    let maxPriceValue = ""
    let charStrip = ""
    let idrCode = ""
    let title = ""
    if (minPrice > 0 && maxPrice > 0) {
      idrCode = "Rp"
      minPriceValue = parseInt(min_price.toString(), 10).toLocaleString("id-ID")
      maxPriceValue = parseInt(max_price.toString(), 10).toLocaleString("id-ID")
      charStrip = "-"
    }

    if (minPrice > 0 && !maxPrice) {
      idrCode = "Rp"
      minPriceValue = parseInt(min_price.toString(), 10).toLocaleString("id-ID")

      charStrip = ""
    }
    if (maxPrice > 0 && !minPrice) {
      idrCode = "Rp"
      maxPriceValue = parseInt(max_price.toString(), 10).toLocaleString("id-ID")
      minPriceValue = "0"

      charStrip = "-"
    }
    if (!minPrice && !maxPrice) {
      title = t("price")
    }

    return `${idrCode} ${minPriceValue} ${charStrip} ${maxPriceValue} ${title}`
  }

  const categoriesSplitToArray =
    categoryAllData?.filter(({ slug }) =>
      categories?.toString().split(",").includes(slug),
    ) || []

  return (
    <section className="h-full w-full">
      <div>
        <nav className="flex h-full w-full items-center gap-4 justify-between">
          {/* select categories */}
          <div className="w-64" ref={categoriesRef}>
            <SelectWithSearch
              label=""
              value={selectedCategory}
              onChange={(selectedOption: { label: string; value: string }) => {
                const { value } = selectedOption
                filterSelectOptionCategory(value)
                setSelectedCategory([selectedOption])
              }}
              placeholder={t("select_category")}
              options={categoryAllData}
              customCss={{ padding: "4px 2px" }}
              noOptionsMessage={t("not_available")}
            />
          </div>
          {/* select Date filter */}
          <div
            ref={dateRef}
            className={`relative border w-[150px] ${
              date_range?.length > 0 ? "border-blue-700" : "border-gray-200"
            } font-heading group flex-auto items-center rounded-lg bg-white text-sm font-medium`}>
            <div
              role="button"
              tabIndex={0}
              // onBlur={() => setCardDate(false)}
              onClick={handleCardDate}
              className="relative flex h-11 w-full items-center justify-between px-5">
              <Text
                color={date_range?.length > 0 ? "gray" : "muted"}
                weight="medium"
                size="small"
                customClass="leading-5">
                {`${
                  date_range?.length > 0
                    ? generateTrainingDate(date_range?.toString().split(",")?.[0], date_range?.toString().split(",")?.[1])
                    : ""
                } ${!date_range ? t("date") : ""}`}
              </Text>

              <Icon
                iconName="arrowBottom"
                styles={`transition ${isCardDateOpen ? "rotate-180" : "rotate-0"}`}
              />
            </div>
            <WrapperAnimateRoute
              type="show"
              isLoading={!isCardDateOpen}
              className={`absolute  -left-4 z-10 ${isCardDateOpen ? "top-9" : "hidden"}`}>
              <div ref={dateRef}>
                <CardDateFilter
                  applyFilterDate={applyFilterDate}
                  resetFilterDate={resetFilterDate}
                />
              </div>
            </WrapperAnimateRoute>
          </div>

          {/* select Price */}
          <div
            ref={priceRef}
            className={`relative border w-[180px] ${
              min_price?.length > 0 || max_price?.length > 0 ? "border-blue-700" : "border-gray-200"
            } font-heading group flex-auto items-center rounded-lg bg-white text-sm font-medium`}>
            <div
              role="button"
              tabIndex={0}
              onClick={handleCardPrice}
              className="relative flex h-11 w-full items-center justify-between px-5">
              <Text
                color={min_price?.length > 0 || max_price?.length > 0 ? "gray" : "muted"}
                weight="medium"
                size="small"
                customClass="leading-5">
                {showPrice(min_price, max_price)}
              </Text>

              <Icon
                iconName="arrowBottom"
                styles={`transition ${isCardPriceOpen ? "rotate-180" : "rotate-0"}`}
              />
            </div>

            <WrapperAnimateRoute
              type="show"
              isLoading={!isCardPriceOpen}
              className={`absolute -left-4 z-20 w-px-390 ${isCardPriceOpen ? "top-9" : "hidden"}`}>
              <div className="w-px-390">
                <CardPriceFilter
                  handleSubmitFilterPrices={handleSubmitFilterPrices}
                  handleResetPrice={handleResetPrice}
                />
              </div>
            </WrapperAnimateRoute>
          </div>

           {/* select Type filter */}
           <div
              ref={typeRef}
              className="relative border w-[120px] font-heading group flex-auto items-center rounded-lg bg-white text-sm font-medium">
              <div
                tabIndex={0}
                role="button"
                onClick={handleShowProgramType}
                className="relative flex h-11 w-full items-center justify-between px-5">
                {classTypes.length >= 3 ? 
                  <Text
                    color="muted"
                    weight="medium"
                    size="small">
                    Program
                  </Text>
                  :
                  <div className="flex gap-x-2">
                    {capitalize(classTypes.join(", ")).replace("Inhouse", "In-house")}
                  </div>
                }
                <div className="flex items-center gap-x-2">
                  <Badge
                    label={(classTypes.length).toString()}
                    bgColor="bg-primary"
                    customClass="rounded w-6 h-6"
                    color="text-white"
                  />
                  <Icon
                    iconName="arrowBottom"
                    styles={`transition ${showTypeProgram ? "rotate-180" : "rotate-0"}`}
                  />
                </div>
              </div>
              <WrapperAnimateRoute
                type="show"
                isLoading={!showTypeProgram}
                className={`absolute left-0 top-4 z-20 h-px-533 w-full ${
                  showTypeProgram ? "top-9" : "hidden"
                }`}>
                <div ref={typeRef} className="h-px-533 w-full">
                  <ClassType 
                    selectedByType={classTypes}
                    onChange={handleClassTypes}
                    resetFilter={() => setClassType(["inhouse", "public"])}
                    applyFilter={() => applyFilterType(classTypes)}
                    />
                </div>
              </WrapperAnimateRoute>
            </div>
        </nav>
      </div>
    </section>
  )
}

export default ProgramsFilter
