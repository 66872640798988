import CardProgram from "@components/shared-components/Card/CardProgram"
import { capitalize } from "lib/helpers"
import LoadingComponent from "lib/components/Loading"
import NotFoundTitle from "lib/components/NotFoundTitle"
import { LanguageContext } from "context/LanguageContext"
import { memo, useContext } from "react"

type ProgramListProps = {
  programs:
    | Array<{
        category_slug: string
        discount: number
        discount_price: number
        final_rating: number
        final_rating_participants: number
        price: number
        program_name: string
        program_thumbnail_url: string
        id: string
        slug: string
        tp_name: string
        tp_profile_url: string
        type: string
        class_code: string
        class_end_date: string
        class_start_date: string
      }>
    | any
  loading: boolean
  sectionName?: string
}

function ProgramList({ programs = [], loading, sectionName }: ProgramListProps) {
  const { useTranslation: t } = useContext(LanguageContext)
  if (loading) {
    return (
      <div className="text-center">
        <LoadingComponent size="small" color="primary" />
      </div>
    )
  }

  if (!loading && programs.length <= 0) {
    return (
      <div className="text-center">
        <NotFoundTitle title={t("program_not_yet_available")} />
      </div>
    )
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-5 gap-4 lg:gap-6 w-full">
      {programs.map((item, index) => (
        <CardProgram
          key={index}
          index={index}
          sectionName={sectionName}
          category={{
            name: item.category_name,
            slug: item.category_slug,
            description: capitalize(item.category_description),
          }}
          discount={item.discount}
          discountPrice={item.discount_price}
          finalRating={item.final_rating}
          finalRatingParticipants={item.final_rating_participants}
          price={item.price}
          programName={item.program_name}
          programThumbnailUrl={item.program_thumbnail_url}
          id={item.id}
          slug={item.slug}
          tpName={item.tp_name}
          tpProfileUrl={item.tp_profile_url}
          type={item.type}
          classCode={item.class_code}
          classEndDate={item.class_end_date}
          classStartDate={item.class_start_date}
          customImage={item.custom_image}
          campaign={item.campaign}
        />
      ))}
    </div>
  )
}

export default memo(ProgramList)
