import { RefObject, useContext, useRef } from "react"
import useSWR from "swr"

import Button from "lib/components/Button"
import Icon from "lib/components/Icons"
import Text from "lib/components/Text"
import { LanguageContext } from "context/LanguageContext"
import fetcher from "lib/fetcher"
import ImageComponent from "lib/components/Image"
import { capitalize } from "lib/helpers"
import SEO from "lib/SEO"
import { NextRouter, useRouter } from "next/router"
import {
  ActivePublicObjects,
  TopRatedProgram,
  TopRatedProgramQuery,
} from "@graphql/homepage/popular_suggestion_program"
import LoadingComponent from "lib/components/Loading"

interface Props {
  onArrowKey: (programName: string) => void
  searchValue: string
  navigateSearch?: (event) => void
  onFocus?: (event) => void
  onLeft?: (event) => void
  inputSearchRef: RefObject<HTMLInputElement>
  isSuggestionSearch?: boolean
  selectedCategory?: string
  padding?: "small" | "medium" | "large" | "none"
}

type RenderProgramProps = {
  dataProgram: any
  key: number
  router: NextRouter
}

const RenderProgram = ({ dataProgram, key, router }: RenderProgramProps) => {
  let imageUrl: string

  if (dataProgram.type !== "public") {
    imageUrl = dataProgram.program_thumbnail_url || SEO.DEFAULT_OG_IMAGE
  } else {
    imageUrl = dataProgram.custom_image || dataProgram.program_thumbnail_url || SEO.DEFAULT_OG_IMAGE
  }

  return (
    <div
      key={key}
      role="button"
      tabIndex={0}
      className="py-1.5 hover:cursor-pointer z-20 flex flex-row gap-x-2 items-center rounded hover:bg-gray-100"
      onClick={() =>
        router.push(
          dataProgram.type === "public"
            ? `/${dataProgram.category_slug}/${dataProgram.type}/${dataProgram.slug}/${dataProgram.class_code}`
            : `/${dataProgram.category_slug}/${dataProgram.type}/${dataProgram.slug}`,
        )
      }>
      <div className="relative aspect-video h-8 sm:h-11 rounded">
        <ImageComponent
          src={imageUrl}
          alt={dataProgram.program_name}
          rounded
          roundedSize="small"
          objectFit="cover"
          className="aspect-video h-11"
        />
      </div>
      <div className="flex flex-col gap-y-1">
        <Text size="small" customClass="text-xs sm:text-sm font-medium">
          {dataProgram.program_name}
        </Text>
        <div className="flex flex-row items-center">
          <Text size="extra-small" customClass="text-[10px] sm:text-xs font-normal">
            {dataProgram?.tp_name}
          </Text>
          <span className="mx-1 sm:mx-2 text-[10px] sm:text-xs">|</span>
          <Text size="extra-small" customClass="text-[1-px] sm:text-xs font-normal">
            {capitalize(dataProgram.type)}
          </Text>
        </div>
      </div>
    </div>
  )
}

export default function SuggestionSearch({
  searchValue,
  navigateSearch,
  isSuggestionSearch,
  selectedCategory,
  onArrowKey,
  onFocus,
  padding,
  onLeft,
  inputSearchRef,
}: Props) {
  const router = useRouter()
  const { useTranslation: t } = useContext(LanguageContext)
  const divContainerRef: RefObject<HTMLDivElement> = useRef()
  let categoryQuery = ""
  if (selectedCategory && selectedCategory !== "all") {
    categoryQuery = `category_slug: {_eq: "${selectedCategory}"}`
  }

  const { data: dataSearchPrograms } = useSWR(
    searchValue
      ? `/api/program/search_program?category=${selectedCategory || "all"}&search=${searchValue}`
      : null,
    fetcher,
  )

  const { data: topRatedProgram, isLoading: loadingTopRated } = useSWR<{
    data: TopRatedProgram
    isLoading: boolean
  }>(searchValue.length === 0 ? "api/homepage/popular_suggestion_program?limit=20" : null, fetcher)

  let paddingContainer = "px-4"
  let paddingContent = "px-4"
  let paddingSearch = "p-0"
  switch (padding) {
    case "small":
      paddingContainer = "px-4"
      paddingContent = "px-4"
      break
    case "medium":
      paddingContainer = "px-0"
      paddingContent = "px-6"
      break
    case "large":
      paddingContainer = "px-0"
      paddingContent = "px-16"
      break
    case "none":
      paddingContainer = "px-0 py-1"
      paddingContent = "px-2"
      paddingSearch = "px-1"
      break
    default:
      break
  }

  return (
    <div
      className={`absolute transition ${paddingContainer} -left-6 sm:-left-9 top-12 sm:top-14 w-screen sm:w-full xl:w-[49rem] z-40 ${
        !isSuggestionSearch && "z-0 -left-[9999px] opacity-0"
      }`}
      role="button"
      onFocus={onFocus}
      onBlur={onLeft}
      onMouseDown={(e) => {
        e.preventDefault() // Prevent blur when SuggestionSearch is clicked
      }}
      tabIndex={0}>
        {loadingTopRated ?
          <div
            className={`shadow-search bg-white py-2.5 ${paddingContent} border border-gray-50 rounded relative z-10 max-h-[25rem] overflow-y-scroll`}
            role="button"
            ref={divContainerRef}
            tabIndex={0}>
              <LoadingComponent size="small" />
          </div>
          :
          <div
            className={`shadow-search bg-white py-2.5 ${paddingContent} border border-gray-50 rounded relative z-10 max-h-[25rem] overflow-y-scroll`}
            role="button"
            ref={divContainerRef}
            tabIndex={0}>
            {searchValue.length !== 0 && (
              <Text customClass="text-sm sm:text-base sm:mb-2" weight="semibold">
                {t("most_popular_programs")}
              </Text>
            )}
            {searchValue.length === 0 ? (
              <>
                {topRatedProgram?.data?.active_public.length > 0 && (
                  <>
                    <Text customClass="text-sm sm:text-base" weight="semibold">
                      {t("active_public_programs")}
                    </Text>
                    {topRatedProgram?.data?.active_public.map(
                      (it: ActivePublicObjects, indexTopRated: number) => (
                        <RenderProgram dataProgram={it} key={indexTopRated} router={router} />
                      ),
                    )}
                  </>
                )}
                {topRatedProgram?.data?.programs.length > 0 && (
                  <>
                    <Text customClass="text-sm sm:text-base mt-2" weight="semibold">
                      {t("most_popular_programs")}
                    </Text>
                    {topRatedProgram?.data?.programs.map(
                      (it: TopRatedProgramQuery, indexTopRated: number) => (
                        <RenderProgram dataProgram={it} key={indexTopRated} router={router} />
                      ),
                    )}
                  </>
                )}
              </>
            ) : (
              <div className="flex flex-col">
                {dataSearchPrograms?.program_published_program?.map((program, index) => (
                  <div
                    key={index}
                    role="button"
                    tabIndex={0}
                    className="p-1.5 hover:cursor-pointer z-20 flex items-center rounded hover:bg-gray-100"
                    onClick={() => navigateSearch(program?.program_name)}>
                    <span className="w-7 opacity-40">
                      <Icon iconName="search" width={24} height={24} />
                    </span>
                    <Text customClass="text-xs sm:text-base font-medium">{program?.program_name}</Text>
                  </div>
                ))}
                {dataSearchPrograms?.inhouse?.length > 0 && (
                  <>
                    <Text customClass="text-sm sm:text-base mt-2" weight="semibold">
                      {t("in_house_program")}
                    </Text>
                    {dataSearchPrograms?.inhouse?.map((programInhouse, indexInhouse) => (
                      <RenderProgram dataProgram={programInhouse} key={indexInhouse} router={router} />
                    ))}
                  </>
                )}
                {dataSearchPrograms?.public?.length > 0 && (
                  <>
                    <Text customClass="text-sm sm:text-base mt-2" weight="semibold">
                      {t("programs_public")}
                    </Text>
                    {dataSearchPrograms?.public?.map((programPublic, indexPublic) => (
                      <RenderProgram dataProgram={programPublic} key={indexPublic} router={router} />
                    ))}
                  </>
                )}
              </div>
            )}
            {searchValue.length > 0 && (
              <Button
                variant="text"
                color="muted"
                customClass={`${paddingSearch} text-xs sm:text-base mt-1`}
                onClick={() => navigateSearch(searchValue)}>
                {`${t("search")} Program/${t("class")} ${t("new_containing")} "${searchValue}"`}
              </Button>
            )}
          </div>
        }
    </div>
  )
}

