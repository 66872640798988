import { convertDateGMT } from "lib/helpers"
import useQueryParams from "hooks/useQueryParams"
import Button from "lib/components/Button"
import { LanguageContext } from "context/LanguageContext"
import { useContext, useEffect, useState } from "react"
import { DateRange } from "react-date-range"
import "react-date-range/dist/styles.css" // main style file
import "react-date-range/dist/theme/default.css"
// theme css file
interface PropsCardDateMobileFilter {
  onChange?: (event: string[]) => void
  resetFilterDate?: () => void
  applyFilterDate?: (event) => void
}
function CardDateFilter({ onChange, resetFilterDate, applyFilterDate }: PropsCardDateMobileFilter) {
  const { useTranslation: t } = useContext(LanguageContext)
  const [selectedRange, setSelectedRange] = useState<string[]>()
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ])
  const queries = useQueryParams()

  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  }
  useEffect(() => {
    if (queries.get("dateRange")) {
      setState([
        {
          startDate: new Date(queries.get("dateRange").split(",")[0]),
          endDate: new Date(queries.get("dateRange").split(",")[1]),
          key: "selection",
        },
      ])
      setSelectedRange(queries.get("dateRange").split(","))
    }
  }, [queries.get("dateRange")])

  return (
    <section className="top-0 left-0 right-0 bottom-0 z-40 flex h-full w-full overflow-y-auto bg-opacity-80 lg:p-4">
      <div className="flex w-full flex-col justify-between rounded-md border bg-white shadow-lg">
        {/* serach categories */}
        <div className="pt-4 px-4 lg:block hidden">
          <h1 className="font-bold text-sm">{t("date")}</h1>
        </div>
        {/* content */}
        <div className="flex flex-row overflow-hidden lg:space-x-3 lg:px-4 lg:pt-4">
          <DateRange
            editableDateInputs
            ranges={state}
            moveRangeOnFirstSelection={false}
            dateDisplayFormat="dd MMM yyyy"
            onChange={(item) => {
              setState([item.selection])
              const startDate = convertDateGMT(item.selection.startDate)
              const endDate = convertDateGMT(item.selection.endDate)
              setSelectedRange([startDate, endDate])
              return onChange?.([startDate, endDate])
            }}
          />
        </div>
        {/* footer */}
        <div className="hidden flex-row items-center border-t px-4 py-4 lg:flex">
          <Button
            variant="text"
            color="white"
            customClass=" w-10"
            colorText="muted"
            fontWeight="semibold"
            onClick={() => {
              if (selectedRange?.length > 0) {
                setState([selectionRange])
                resetFilterDate()
              }
            }}>
            {t("reset")}
          </Button>
          <div className="flex w-full items-end justify-end">
            <Button
              variant="contained"
              color="primary"
              rounded="large"
              size="small"
              onClick={() => {
                if (selectedRange?.length > 0) {
                  applyFilterDate(selectedRange)
                }
              }}
              customClass="flex flex-wrap justify-center w-28 px-4 py-2.5 hover:bg-blue-600 font-medium text-base text-white border border-blue-500 rounded-md shadow-button">
              {t("apply")}
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CardDateFilter
