import CardPriceFilter from "@components/shared-components/Card/CardPriceFilter"
import ModalFilterCategories from "@components/shared-components/Modal/ModalFilterCategories"
import { formatStringToDateFormat } from "lib/helpers"
import useQueryParams from "hooks/useQueryParams"
import Button from "lib/components/Button"
import CheckBox from "lib/components/Checkbox"
import Icon from "lib/components/Icons"
import Text from "lib/components/Text"
import { ChangeEvent, forwardRef, useContext, useEffect, useState } from "react"
import DatePicker from "react-datepicker"

import Badge from "@components/shared-components/ui/Badge"
import { CategoryContext } from "context/CategoryContext"
import { LanguageContext } from "context/LanguageContext"
import "react-datepicker/dist/react-datepicker.css"
import ContainerModalCustom from "../ModalContainer"

interface Props {
  isOpen: boolean
  onClose: () => void
  navigateClassTypes?: (event) => void
  navigateSort?: (event) => void
  navigateCategories?: (event) => void
  navigatePrice?: (event, secEvent) => void
  navigateDate?: (event) => void
  resetFilter?: () => void
  resetKeyword?: () => void
  applyFilters?: () => void
  onSelectCategory?: (categorySlug: string, selectedTypes: string, date: string, minPrice: string, maxPrice: string, sortBy: string) => void
  categorySelectType: "single" | "multiple"
  filterData?: {
    minPrice?: string
    maxPrice?: string
    categories?: string[]
    classType?: string[]
    sortBy?: string
    date?: string[]
  }
}

// header component
function HeaderModal() {
  return (
    <div className="fixed flex h-16 w-full items-center border-b bg-white px-4 z-10">
      <h1 className="text-base font-semibold">Filter</h1>
    </div>
  )
}

// footer component
interface PropsFooterModal {
  onSubmitFilter: () => void
  onResetFilter: () => void
}
function FooterModal({ onSubmitFilter, onResetFilter }: PropsFooterModal) {
  const { useTranslation: t } = useContext(LanguageContext)

  return (
    <div className="fixed bottom-0 w-full border-t bg-white py-4 px-4">
      <Button
        variant="contained"
        color="primary"
        customClass="w-full mb-2"
        onClick={onSubmitFilter}>
        {`${t("apply")} Filter`}
      </Button>
      <Button
        colorText="muted"
        variant="text"
        color="default"
        customClass="w-full"
        onClick={onResetFilter}>
        {`${t("reset")} Filter`}
      </Button>
    </div>
  )
}

// filter by date component

interface PropsFilterByDate {
  onChange: (event) => void
}
const CustomDate = forwardRef(
  ({ value, onClick, label }: { label?: string; value?: string; onClick?: () => void }, ref) => (
    <Button
      variant="text"
      color="default"
      customClass="w-full py-3"
      onClick={onClick}
      fontWeight="medium"
      size="small"
      align="left">
        {value ?
          <Text>
            {`${formatStringToDateFormat(`${value}`, "MMM DD")}`}
          </Text>
          :
          <Text customClass="text-muted">
            {label}
          </Text>
        }
    </Button>
  ),
)
CustomDate.displayName = "CustomDate"

function FilterByDate({ onChange }: PropsFilterByDate) {
  const { useTranslation: t } = useContext(LanguageContext)

  const [startDate, setStartDate] = useState<string | Date>(null)
  const [endDate, setEndDate] = useState<string | Date>(null)
  const queries = useQueryParams()

  useEffect(() => {
    if (queries.get("date_range")?.length > 0) {
      const dateRange = queries.get("date_range")?.split(",")
      setStartDate(new Date(dateRange?.[0]))
      setEndDate(new Date(dateRange?.[1]))
    }
  }, [queries.get("date_range")])

  return (
    <div className="block px-4 py-4">
      <Text weight="bold" customClass="block" size="small">
        {t("date")}
      </Text>
      <div className="flex space-x-4 pt-3">
        <div className="font-heading  group relative grid w-full grid-cols-2 gap-4 rounded-lg bg-white text-sm font-medium">
          <div
            role="button"
            tabIndex={0}
            className="flex w-full items-center justify-between rounded-lg border border-gray-200 px-2">
            <div className="relative w-full">
              <DatePicker
                selected={startDate}
                onChange={(date: Date) => {
                  setStartDate(date)
                  onChange(
                    `${formatStringToDateFormat(date, "MM-DD-YYYY")},${endDate ? formatStringToDateFormat(
                      endDate,
                      "MM-DD-YYYY") : formatStringToDateFormat(
                        date,
                        "MM-DD-YYYY")}`,
                  )
                }}
                customInput={<CustomDate label={`Min. ${t("date")}`} />}
              />
            </div>
            <div className="relative">
              <Icon iconName="arrowBottom" />
            </div>
          </div>
          <div className="relative flex w-full items-center justify-between rounded-lg border border-gray-200 px-2">
            <div className="relative  w-full">
              <DatePicker
                selected={endDate}
                onChange={(date: Date) => {
                  setEndDate(date)
                  onChange(
                    `${startDate ? formatStringToDateFormat(
                      startDate,
                      "MM-DD-YYYY",
                    ) : formatStringToDateFormat(
                      new Date(),
                      "MM-DD-YYYY",
                    )},${formatStringToDateFormat(date, "MM-DD-YYYY")}`,
                  )
                }}
                customInput={<CustomDate label={`Max. ${t("date")}`} />}
                popperPlacement="top-end"
              />
            </div>
            <div className="relative">
              <Icon iconName="arrowBottom" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// filter by price

interface PropsFilterByPrice {
  onChangeMinValue: (e) => void
  onChangeMaxValue: (e) => void
}

function FilterByPrice({ onChangeMinValue, onChangeMaxValue }: PropsFilterByPrice) {
  const { useTranslation: t } = useContext(LanguageContext)

  return (
    <div className="z-0 px-4 py-4">
      <Text weight="bold" customClass="block" size="small">
        {t("price")}
      </Text>
      <div className="z-0 flex space-x-4">
        <CardPriceFilter
          onChangeMaxValue={(e) => onChangeMaxValue(e)}
          onChangeMinValue={(e) => onChangeMinValue(e)}
        />
      </div>
    </div>
  )
}

// filter by class
export interface PropsClassType {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  selectedByType: string[]
  resetFilter?: () => void
  applyFilter?: () => void
}

function ClassType({ onChange, selectedByType }: PropsClassType) {
  const { useTranslation: t } = useContext(LanguageContext)
  return (
    <div className="flex flex-col space-y-4 py-4 px-4">
      {/* Filter class type */}
      <div>
        <Text weight="bold" customClass="block" size="small">
          {t("class_type")}
        </Text>
        <div className="grid grid-cols-2 w-full gap-2 pt-3">
          <CheckBox
            label="In-house"
            onChange={onChange}
            value="inhouse"
            checked={selectedByType?.includes("inhouse")}
          />
          <CheckBox
            label="Public"
            onChange={onChange}
            value="public"
            checked={selectedByType?.includes("public")}
          />
          {/* <CheckBox
            label="Bootcamp"
            onChange={onChange}
            value="bootcamp"
            checked={selectedByType?.includes("bootcamp")}
          /> */}
        </div>
      </div>
    </div>
  )
}

// component categories
interface PropsCategory {
  handleOnFocus: () => void
  categoryData: string | string[]
}
function FilterByCategory({ handleOnFocus, categoryData }: PropsCategory) {
  const { categories } = useContext(CategoryContext)
  const { useTranslation: t } = useContext(LanguageContext)

  // ─── Convert Category Data To Array ──────────────────────────────────
  const CategoryConvertToArray = categoryData?.toString().split(",")
  // ─────────────────────────────────────────────────────────────────────

  // ─── Filter Category To Find Match Slug ──────────────────────────────
  const categoriesSplitToArray = categories?.data?.program_category?.filter(({ slug }) =>
    CategoryConvertToArray.includes(slug),
  )
  // ─────────────────────────────────────────────────────────────────────

  return (
    <div className="relative px-4 py-4" role="button" tabIndex={0} onClick={handleOnFocus}>
      <div className="flex w-full pt-3 lg:space-x-10">
        <div className="mr-auto flex w-full justify-between items-center rounded-lg border pl-4 py-2">
          <div className="flex">
            <div className="mr-2 text-gray-600 hover:text-gray-900">
              <Icon iconName="search" />
            </div>
            {categoryData?.length === 0 ? (
              <Text
                color={categoryData?.length > 0 ? "gray" : "muted"}
                weight="medium"
                size="small">
                {t("categories")}
              </Text>
            ) : (
              <div className="flex gap-4">
                {categoriesSplitToArray?.map((category, index) => {
                  if (index < 2) {
                    return (
                      <Badge
                        key={index}
                        label={category.category_name}
                        bgColor="bg-primary"
                        customClass="rounded"
                        color="text-white"
                      />
                    )
                  }
                  return false
                })}
              </div>
            )}
          </div>

          {categoryData.length > 2 && (
            <div className="mr-2 flex h-7 w-8 items-center justify-center rounded-lg bg-blue-100 p-2">
              <Text size="extra-small" color="gray">{`+${categoryData.length - 2}`}</Text>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

// sort program by
interface PropsSort {
  onSortBy: (event: string) => void
  sortBy: string | string[]
}

function SortProgramBy({ onSortBy, sortBy }: PropsSort) {
  const { useTranslation: t } = useContext(LanguageContext)

  const handleSubmit = (e) => {
    onSortBy(e.target.value)
  }
  return (
    <div className="px-4 pt-4 pb-24 mb-2">
      <Text weight="bold" customClass="block" size="small">
        {t("sort_by")}
      </Text>
      <div className="flex flex-col space-y-2 pt-3">
        <form className="flex flex-col space-y-3">
          <label htmlFor="newProgramOptions" className="flex space-x-2">
            <input
              type="radio"
              id="newProgramOptions"
              name="newProgramOptions"
              value="created_at:desc"
              onChange={handleSubmit}
              checked={sortBy.includes("created_at:desc")}
            />
            <Text size="small" weight="medium">
              {t("newest_program")}
            </Text>
          </label>
          <label htmlFor="lowerPrice" className="flex space-x-2">
            <input
              type="radio"
              id="lowerPrice"
              name="lowerPrice"
              value="discount_price:asc"
              onChange={handleSubmit}
              checked={sortBy.includes("discount_price:asc")}
            />
            <Text size="small" weight="medium">
              {t("price_low_to_high")}
            </Text>
          </label>
        </form>
      </div>
    </div>
  )
}

function ModalAllFilters({
  isOpen,
  onClose,
  navigateClassTypes,
  navigateSort,
  navigateCategories,
  navigatePrice,
  navigateDate,
  resetFilter,
  resetKeyword,
  applyFilters,
  filterData,
  onSelectCategory,
}: Props) {
  const queries = useQueryParams()
  const selectedTypes = queries.get("type")
  const dateRange = queries.get("date_range")
  const [classTypes, setClassType] = useState<string[]>(["inhouse", "public"])
  const [date, setDate] = useState<string[]>([])
  const [minPrice, setMinPrice] = useState<string>()
  const [maxPrice, setMaxPrice] = useState<string>()
  const [categories, setCategories] = useState<string[] | string>([])
  const [sortBy, setSortBy] = useState<string | string[]>("created_at:desc")
  const [isModalCategoryOpen, setModalCategoryOpen] = useState<boolean>(false)

  useEffect(() => {
    if (filterData?.minPrice) {
      setMinPrice(filterData?.minPrice)
    }

    if (filterData?.maxPrice) {
      setMaxPrice(filterData?.maxPrice)
    }

    if (filterData?.categories) {
      setCategories(filterData?.categories)
    }

    if (filterData?.sortBy) {
      setSortBy(filterData?.sortBy)
    }

    if (filterData?.classType) {
      setClassType(filterData?.classType)
    }

    if (filterData?.date) {
      setDate(filterData?.date)
    }

    return () => {
      setMinPrice(null)
      setMaxPrice(null)
      setCategories([])
      setDate([])
      setSortBy("created_at:desc")
      setDate([])
      setModalCategoryOpen(false)
      setClassType(["inhouse", "public"])
    }
  }, [filterData])

  const handleClassTypes = (e) => {
    setClassType(() => {
      if (e.target.checked) {
        if (classTypes?.length > 0) {
          return [...classTypes.filter((val) => val !== e.target.value), e.target.value]
        }
        return [e.target.value]
      }
      return [...classTypes.filter((val) => val !== e.target.value)]
    })
  }

  useEffect(() => {
    if (selectedTypes) {
      const dataSelectedType = selectedTypes.split(",")
      setClassType(dataSelectedType)
    }
    setDate([dateRange as string])
  }, [selectedTypes, dateRange])

  // handle filter date
  const handleDateFilter = (e) => setDate(e)

  const handleOnFocus = () => {
    setModalCategoryOpen(!isModalCategoryOpen)
  }

  // handle sort
  const handleSort = (e) => {
    setSortBy(e)
  }

  // apply filter
  const applyFilter = async () => {
    await resetKeyword?.()
    await navigateClassTypes(classTypes)
    await navigateSort(sortBy)
    await navigateCategories(categories)
    await navigatePrice(minPrice, maxPrice)
    await navigateDate(date)
    await applyFilters?.()
  }

  useEffect(() => {
    if (isOpen) {
      window.scrollTo(0, 1)
    }
  }, [isOpen])

  return (
    <ContainerModalCustom
      closeModal={onClose}
      isModalOpen={isOpen}
      positionTopCloseX="top-2.5"
      positionCloseX="right-3">
      <div className="h-screen w-screen overflow-x-hidden overflow-y-scroll border bg-white pb-28">
        {isOpen && (
          <style>{`
            .qcw-trigger-btn {
              display: none !important;
            }
          `}</style>
        )}
        <HeaderModal />
        <div className="mb-16 pb-2" />
        <ClassType onChange={handleClassTypes} selectedByType={classTypes} />
        <FilterByDate onChange={(e) => handleDateFilter(e)} />{" "}
        <FilterByPrice
          onChangeMaxValue={(e) => setMaxPrice(e)}
          onChangeMinValue={(e) => setMinPrice(e)}
        />
        <FilterByCategory handleOnFocus={handleOnFocus} categoryData={categories} />
        <SortProgramBy onSortBy={handleSort} sortBy={sortBy} />
      </div>
      <FooterModal onSubmitFilter={applyFilter} onResetFilter={resetFilter} />

      {/* modal categories */}
      <ModalFilterCategories
        onClose={() => setModalCategoryOpen(false)}
        isOpen={isModalCategoryOpen}
        onSelectCategory={(valueCategory) => {
          onSelectCategory(valueCategory, classTypes.toString(), date.toString(), minPrice, maxPrice, sortBy.toString())
        }}
      />
      {/* modal categories */}
    </ContainerModalCustom>
  )
}

export default ModalAllFilters
