import useSWR from "swr"

import WrapperAnimate from "@components/shared-components/WrapperAnimate/WrapperAnimateShow"
import fetcher from "lib/fetcher"
import useQueryParams from "hooks/useQueryParams"
import Button from "lib/components/Button"
import Text from "lib/components/Text"
import { LanguageContext } from "context/LanguageContext"
import { useContext, useEffect, useState } from "react"
import Icon from "lib/components/Icons"
import ContainerModalCustom from "../ModalContainer"

interface Props {
  isOpen: boolean
  onClose: () => void
  handleNavigate?: (event) => void
  onChange?: (event) => void
  openModalAllFilters?: () => void
  totalFilter?: number
}

interface PropsListResult {
  keyword: string
  handleNavigate?: (event) => void
}
interface PropsListComponent {
  keyword: string
  listData: [
    {
      program_name: string
      tp_name: string
      id: string
    },
  ]
  handleNavigate?: (event) => void
}

function ListComponent({ listData, keyword, handleNavigate }: PropsListComponent) {
  const { useTranslation: t } = useContext(LanguageContext)

  return (
    <div className="relative flex h-screen-90 flex-col">
      {listData?.map((val, index) => (
        <div key={index} className="px-4">
          <div
            className="border-b py-4"
            role="button"
            tabIndex={0}
            onClick={() => handleNavigate(val?.program_name)}>
            <Text size="small" weight="semibold" color="gray">
              {val?.program_name}
            </Text>
            <Text size="extra-small" color="muted">
              {val?.tp_name}
            </Text>
          </div>
        </div>
      ))}
      <Button
        variant="contained"
        color="primary"
        rounded="large"
        onClick={() => handleNavigate(keyword)}
        customClass="absolute bottom-0 w-[343px]  left-1/2 transform -translate-x-1/2"
        iconRight="arrow-right">
        {t("see_all_result")}
      </Button>
    </div>
  )
}

function ListResultSearch({ keyword, handleNavigate }: PropsListResult) {
  const { data: dataSearchPrograms, isLoading } = useSWR(`/api/program/search_program_name?search=${keyword}`, fetcher)

  return (
    <WrapperAnimate isLoading={isLoading}>
      <div>
        {keyword?.length > 0 && (
          <ListComponent
            keyword={keyword}
            listData={dataSearchPrograms?.program_published_program}
            handleNavigate={handleNavigate}
          />
        )}
      </div>
    </WrapperAnimate>
  )
}

function ModalSearch({ isOpen, onClose, handleNavigate, openModalAllFilters, onChange, totalFilter }: Props) {
  const { useTranslation: t } = useContext(LanguageContext)
  const queries = useQueryParams()

  const [searchValue, setSearchValue] = useState("")

  const handleSubmitSearch = (event) => {
    if (event.key === "Enter") {
      handleNavigate(searchValue)
    }
  }

  useEffect(() => {
    if (queries.get("keyword")) {
      setSearchValue(queries.get("keyword"))
    }
  }, [queries.get("keyword")])

  return (
    <ContainerModalCustom
      positionCloseX="left-0"
      customIconCloseX="arrowLeftBack"
      positionTopCloseX="top-2"
      closeModal={onClose}
      isModalOpen={isOpen}>
      <div className="z-10 h-screen w-screen overflow-scroll bg-white">
        <div className="relative flex items-center justify-between border-b">
          {isOpen && (
            <style>{`
              .qcw-trigger-btn {
                display: none !important;
              }
            `}</style>
          )}
          <div className="flex w-full items-center justify-between">
            <input
              value={searchValue}
              onChange={(event) => {
                setSearchValue(event.target.value)
                onChange?.(event.target.value)
              }}
              onKeyDown={handleSubmitSearch}
              name="search-mobile"
              className="ml-10 w-full border-none p-4 text-sm font-medium leading-5 text-gray-900 outline-none"
              type="text"
              placeholder={t("what_do_you_want_to_learn")}
              autoComplete="off"
            />
          </div>
          <div
            role="button"
            tabIndex={0}
            className={`bg-white p-1.5 flex items-center justify-center border ${totalFilter > 0 ? "border-primary" : "border-gray-200"} rounded`}
            onClick={openModalAllFilters}
          >
            <Icon iconName="adjustments" width={22} height={22} color={totalFilter > 0 ? "#286DCC" : "#000"} type="stroke" />
          </div>
          {totalFilter > 0 && (
            <div className="absolute top-0 right-5 h-4 w-4 bg-primary rounded-full flex items-center justify-center text-white">
              <span className="text-[10px] font-medium">{totalFilter}</span>
            </div>
          )}
        </div>

        {/* list render */}
        <ListResultSearch handleNavigate={handleNavigate} keyword={searchValue} />
      </div>
    </ContainerModalCustom>
  )
}

export default ModalSearch
