import Text from "lib/components/Text"
import { forwardRef, useEffect, useRef } from "react"

export interface CheckboxProps {
  value?: string;
  label?: string;
  labelComponent?: React.ReactNode;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  error?: boolean;
  labelFor?: string;
  withImage? : boolean;
  disabled?: boolean
  indeterminate?: boolean,
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(({
  value, onChange, label, checked=false, error, labelComponent, labelFor, withImage = false, disabled = false, indeterminate = false,
}: CheckboxProps, checkboxRef) => {
  Checkbox.displayName = "Checkbox"
  const defaultRef = useRef<any>(null)
  const resolvedRef:any = checkboxRef || defaultRef

  useEffect(() => {
    if (resolvedRef.current) {
      resolvedRef.current.indeterminate = indeterminate
    }
  }, [resolvedRef, indeterminate])

  let checkboxColor = "text-gray-500"

  if (error) {
    checkboxColor = "text-red-500"
  }

  return (
    <div className="flex flex-col gap-y-4">
      <label htmlFor={labelFor || label} className={`flex ${withImage ? "items-start" : "items-center"} gap-x-2`}>
        <input
          className={`w-4 h-4 flex-shrink-0 ${checkboxColor}`}
          type="checkbox"
          checked={checked}
          value={value}
          onChange={onChange}
          ref={resolvedRef}
          id={labelFor || label}
          disabled={disabled}
        />
        {label && (
          <div className="flex-grow">
            <Text type="body" color="dark">
              {label}
            </Text>
          </div>
        )}
        {labelComponent}
      </label>
    </div>
  )
})

export default Checkbox
