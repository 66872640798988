import CategoryListDesktop from "@components/Category"
import CategoryListMobile from "@components/Category/mobile"
import SuggestionSearch from "@components/List/SuggestionSearchList"
import Overlay from "@components/Modal/Overlay"
import { SearchBar } from "@components/Sections/Homepage/HomeSearch"
import ModalAllFilters from "@components/shared-components/Modal/ModalAllFIlter"
import ModalSearch from "@components/shared-components/Modal/ModalSearch"
import ProgramsFilter from "@components/shared-components/Programs/ProgramFilters"
import WrapperAnimateRoute from "@components/shared-components/WrapperAnimate/WrapperAnimateShow"
import SkeletonProgramsAll from "@components/Skeleton/SkeletonProgramsAll"
import { GetCategoriesQuery } from "@graphql/program/getCategories"
import {
  GetCategoryBySlugQuery,
  GetCategoryBySlugQueryVariables,
  GET_CATEGORY_BY_SLUG,
} from "@graphql/program/getCategoryBySlug"
import { GetProgramsByCategoryQuery } from "@graphql/program/getProgramsByCategory"
import { initializeApollo } from "lib/apollo"
import fetcher from "lib/fetcher"
import { gtmEcommerce } from "lib/gtm"
import { capitalize } from "lib/helpers"
import useQueryParams from "hooks/useQueryParams"
import useWindowSize from "hooks/useWindowSize"
import { filterItem } from "lib/interfaces"
import Button from "lib/components/Button"
import Icon from "lib/components/Icons"
import SelectWithSearch from "lib/components/SelectOption/withSearch"
import Text from "lib/components/Text"
import { keySelectCategory } from "lib/components/CategorySelector"
import { LanguageContext } from "context/LanguageContext"
import { motion } from "framer-motion"
import { NextSeo } from "next-seo"
import { useRouter } from "next/router"
import { useContext, useEffect, useMemo, useRef, useState } from "react"
import useSWR from "swr"
import url from "url"

type QueryParamsUrl =
  | "date_range"
  | "categories"
  | "type"
  | "keyword"
  | "min_price"
  | "max_price"
  | "sort_by"
  | "page"

type Props = {
  dataCategory: GetCategoryBySlugQuery["program_category"]
  descriptionName: string
  metaTitle: string
  openGraphImage: string
}

const apolloClient = initializeApollo()

export const sortList = [
  { key: "created_at:desc", label: "newest_program", show: true },
  { key: "viewed:desc", label: "most_viewed", show: false },
  { key: "discount_price:asc", label: "price_low_to_high", show: true },
  { key: "discount_price:desc", label: "price_high_to_low", show: false },
  { key: "rated:desc", label: "top_rated", show: false },
]

export const getServerSideProps = async (ctx) => {
  let activeLocale = ctx.locale
  const categorySlug = ctx.params.category
  const language = ctx.query.lang

  if (language) {
    activeLocale = language
  }

  const dataLanguage = await import(
    `../../public/locales/${activeLocale.toUpperCase()}/translations.json`
  )

  const { data } = await apolloClient.query<
    GetCategoryBySlugQuery,
    GetCategoryBySlugQueryVariables
  >({
    variables: {
      slug: categorySlug,
    },
    query: GET_CATEGORY_BY_SLUG,
    fetchPolicy: "no-cache",
  })

  let descriptionName = dataLanguage.we_have_many_training_here
  let metaTitle = dataLanguage.all_category
  let openGraphImage = "https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1721139863/open-graph/page/home.jpg"

  if (categorySlug !== "all" && data.program_category.length > 0) {
    if (activeLocale.toLowerCase() === "en") {
      descriptionName = data.program_category[0].meta_description || data.program_category[0].description
      metaTitle = data.program_category[0].meta_title || data.program_category[0].name
    } else {
      descriptionName = data.program_category[0].meta_description_indo || data.program_category[0].description
      metaTitle = data.program_category[0].meta_title_indo || data.program_category[0].name
    }
    
    openGraphImage = data.program_category[0].open_graph_image || "https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1721139863/open-graph/page/home.jpg"
  }

  return {
    props: {
      dataCategory: data.program_category,
      descriptionName,
      metaTitle,
      openGraphImage,
    },
  }
}

function countFilters(query: any) {
  const filterKeys = ["categories", "date_range", "trainer", "max_price", "min_price", "sort_by", "type"]
  let count = 0

  filterKeys.forEach((key) => {
    if (query[key]) {
      count += 1
    }
  })

  return count
}

function Category({ dataCategory, descriptionName, metaTitle, openGraphImage }: Props) {
  const { useTranslation: t, language } = useContext(LanguageContext)
  const [dataPrograms, setDataPrograms] = useState([])
  const [categoryAllData, setCategoryAllData] = useState([])
  const [selectedPage, setSelectedPage] = useState(1)
  const [isModalSearchOpen, setModalSearchOpen] = useState<boolean>(false)
  const [isModalFilterOpen, setModalFilterOpen] = useState<boolean>(false)
  const [isOverlayOpen, setOverlayOpen] = useState<boolean>(false)
  const [isSuggestionSearch, setSuggestionSearch] = useState(false)
  const [filterData, setFilterData] = useState({})
  const [width] = useWindowSize()
  const isDesktop = width >= 600
  const inputSearchRef = useRef()

  const queries = useQueryParams()
  const keywordSearch = queries.get("keyword")
  const [searchValue, setSearchValue] = useState("")
  const [categorySelected, setCategorySelected] = useState([])
  const { data: allCategories } = useSWR<GetCategoriesQuery>("/api/categories", fetcher)
  const [searchValueArrowKey, setSearchValueArrowKey] = useState("")
  const [sectionDescription, setSectionDescription] = useState<string>("")
  const [programPublishedUrl, setProgramPublishedUrl] = useState(null)
  const [totalProgram, setTotalProgram] = useState(0)
  const [totalFilter, setTotalFilter] = useState<number>(null)
  const { data, isLoading, isValidating } = useSWR<GetProgramsByCategoryQuery>(programPublishedUrl, fetcher)

  /**
   * router
   */
  const router = useRouter()

  function getQueryParams(key: QueryParamsUrl) {
    return queries.get(key) || ""
  }
  function setQueryParams(key: QueryParamsUrl, value: string) {
    queries.set(key, value)
  }
  function deleteQueryParams(key: QueryParamsUrl) {
    queries.delete(key)
  }

  /**
   * queries
   */
  /**
   *
   *
   * search keyword (from query param)
   */
  const keyword = getQueryParams("keyword")
  /**
   * programs must be hidden by types (from query param)
   */
  const selectedByType = getQueryParams("type") ? getQueryParams("type").split(",") : []
  /**
   * programs sorted by (from query param)
   */
  const sort = getQueryParams("sort_by")

  /**
   * active sort
   */
  const activeSort = sort ? sortList.find((item) => item.key === sort) : sortList[0]
  /**
   * active sort label
   */
  const activeSortLabel = activeSort?.label
  /**
   * visible page, if desktop size use query param, if mobile size using state page
   */
  const page = isDesktop ? +(getQueryParams("page") || "1") : selectedPage
  /**
   * offset data programs, calculate offset when page more than 0
   */

  const { category: categorySlugQuery } = router.query

  /**
   * category slug
   */
  const categorySlug = `${categorySlugQuery}`
  /**
   * object of order based on hasura query
   */
  const shortPublicProgramByClassStart = []
  selectedByType.filter((type) => {
    if (type !== "public") {
      shortPublicProgramByClassStart.push({ class_start_date: "asc" })
    }
    return false
  })

  /**
   * data available programs
   */
  const programs = useMemo(() => {
    if (data) {
      return data.program_published_program
    }
    return []
  }, [data])

  if (keyword) {
    descriptionName = `${totalProgram} Training`
  }

  let sectionNameLayer = "All training from selected category"
  let sectionName = t("all_training_from_selected_category")

  if (keywordSearch) {
    sectionName = t("search_result")
    sectionNameLayer = "Search Result"
  }

  if (categorySlug !== "all" && dataCategory.length > 0) {
    sectionName =
      language === "EN"
        ? `${t("all")} ${dataCategory[0].name} ${t("training")}`
        : `${t("all")} ${t("training")} ${dataCategory[0].name}`
    sectionNameLayer = `${t("all")} ${dataCategory[0].name}`
  }

  /**
   * handle reset data programs and selected page on change filter.
   * (change search, sort, toggle, category)
   */
  const handleResetOnChangeFilter = () => {
    if (!isDesktop) {
      setDataPrograms([])
      setSelectedPage(0)
    }
    deleteQueryParams("page")
  }

  /**
   * change category
   */

  /**
   * change filter toggle
   */
  const handleChangeFilter = (types: string[]) => {
    handleResetOnChangeFilter()
    if (types.length > 0) {
      setQueryParams("type", types.toString())
    }
    if (types.length === 3) {
      deleteQueryParams("type")
    }

    if (categorySelected.length > 0) {
      if (categorySelected[0].value !== "all") {
        setQueryParams("categories", categorySelected[0].value)
      } else {
        deleteQueryParams("categories")
      }
    }

    const queryString = queries.toString()

    const pathname = queryString.length
      ? `/programs/${categorySlug}?${queryString}`
      : `/programs/${categorySlug}`

    return router.push(pathname)
  }

  /**
   * change sort
   */
  const handleChangeSort = (event) => {
    handleResetOnChangeFilter()
    const dataSort = event.currentTarget.dataset.sort

    if (dataSort) {
      setQueryParams("sort_by", dataSort)
    }
    if (!dataSort) {
      deleteQueryParams("sort_by")
    }
    const queryString = queries.toString()

    const pathname = queryString.length
      ? `/programs/${categorySlug}?${queryString}`
      : `/programs/${categorySlug}`

    return router.push(pathname)
  }

  /**
   * search on submit
   */
  const handleSearch = (programName = null) => {
    handleResetOnChangeFilter()
    if (searchValue) {
      setQueryParams("keyword", programName || searchValue)
    }
    if (!searchValue) {
      deleteQueryParams("keyword")
    }
    
    const slug = categorySelected[0]?.value || categorySlug
    const pathname =
      searchValue ? `/programs/${slug}?keyword=${programName || searchValue}` : `/programs/${slug}`

    return router.push(pathname)
  }

  const handleSearchSuggestion = (programName = null) => {
    handleResetOnChangeFilter()

    const pathname = `/programs/all?keyword=${programName || searchValue}`

    return router.push(pathname)
  }

  /**
   * change page on click pagination
   */
  const handleChangePage = ({ selected }) => {
    if (selected) {
      setQueryParams("page", selected + 1)
    }
    if (!selected) {
      deleteQueryParams("page")
    }
    const queryString = queries.toString()

    const pathname = queryString.length
      ? `/programs/${categorySlug}?${queryString}`
      : `/programs/${categorySlug}`

    return router.push(pathname)
  }

  const handleQueryParams = (value: any) => {
    let categoryActive = categorySlug
    if (value) {
      setQueryParams("keyword", value)
    }

    if (!value) {
      deleteQueryParams("keyword")
    }

    if (categorySelected.length > 0) {
      categoryActive = categorySelected[0].value
    }

    const queryString = queries.toString()

    const pathname = queryString.length
      ? `/programs/${categoryActive}?${queryString}`
      : `/programs/${categoryActive}`

    return router.push(pathname)
  }
  
  const resetCategories = () => {
    deleteQueryParams("categories")
    const queryString = queries.toString()

    const pathname = queryString.length ? `/programs/all?${queryString}` : "/programs/all"
    return router.push(pathname)
  }

  const handleSubmitSearch = (event: any) => {
    if (event.key === "Enter") {
      setSuggestionSearch(false)
      setOverlayOpen(false)
      handleQueryParams(event.target.value)
    }
  }

  useEffect(() => {
    const filterURL: filterItem[] = []
    const dataFilter = {}

    if (router.query?.category) {
      filterURL.push({
        filterName: "category",
        value: router.query.category,
      })
    }

    if (router.query?.categories) {
      filterURL.push({
        filterName: "categories",
        value: router.query.categories,
      })
      const selectedCategory = router.query.categories.toString().split(",")
      Object.assign(dataFilter, { categories: selectedCategory })
    }

    if (router.query?.date_range) {
      filterURL.push({
        filterName: "date_range",
        value: router.query.date_range,
      })
      const selectedDateRange = router.query.date_range.toString().split(",")
      Object.assign(dataFilter, { date: selectedDateRange })
    }

    if (router.query?.trainer) {
      filterURL.push({
        filterName: "trainer",
        value: router.query.trainer,
      })
    }

    if (router.query?.user_id) {
      filterURL.push({
        filterName: "user_id",
        value: router.query.user_id,
      })
    }

    if (router.query?.max_price) {
      filterURL.push({
        filterName: "max_price",
        value: router.query.max_price,
      })
      Object.assign(dataFilter, { maxPrice: router.query.max_price })
    }

    if (router.query?.min_price) {
      filterURL.push({
        filterName: "min_price",
        value: router.query.min_price,
      })
      Object.assign(dataFilter, { minPrice: router.query.min_price })
    }

    if (router.query?.sort_by) {
      filterURL.push({
        filterName: "sort_by",
        value: router.query.sort_by,
      })
      Object.assign(dataFilter, { sortBy: router.query.sort_by })
    }

    if (router.query?.type) {
      filterURL.push({
        filterName: "type",
        value: router.query.type,
      })
      const selectedSelectedType = router.query.type.toString().split(",")
      Object.assign(dataFilter, { classType: selectedSelectedType })
    }

    if (router.query?.keyword) {
      filterURL.push({
        filterName: "keyword",
        value: router.query.keyword,
      })
      setSearchValue(router.query.keyword as string)
    }

    if (router.query?.page) {
      filterURL.push({
        filterName: "page",
        value: router.query.page,
      })
      setSelectedPage(Number(router.query.page))
    }
    setTotalFilter(countFilters(router.query))
    const filterURLString = filterURL.map(
      (itemFilter) => `${itemFilter.filterName}=${itemFilter.value}`,
    )

    let mainURL = "/api/program/published_program"
    if (filterURLString.length > 0) {
      mainURL += `?${filterURLString.join("&")}`
    }
    setProgramPublishedUrl(mainURL)
    setFilterData(dataFilter)
    setTotalProgram(data?.program_published_program_aggregate?.aggregate.count)

    return () => {
      setSearchValue("")
    }
  }, [router.query, selectedPage, data, totalFilter])

  useMemo(() => {
    // if desktop size, set programs state from response data
    if (programs && isDesktop) {
      if (programs.length > 0) {
        gtmEcommerce({ ecommerce: null })
        gtmEcommerce({
          event: "view_item_list",
          ecommerce: {
            items: programs.map((item, index) => ({
              item_list_id: sectionNameLayer.toLowerCase().replace(/ /g, "-"), // adjust to section id
              item_list_name: sectionNameLayer, // adjust to section name
              item_id: item.id, // adjust to item id
              item_name: item.program_name, // adjust to item name
              item_brand: item.tp_name, // adjust to item brand
              item_category: capitalize(item.category_slug, "-"), // adjust to item category
              item_variant: `${capitalize(item.type)} Program`, // or Public Program, Bootcamp Program
              index: index + 1, // adjust to the promotion index/slot
              currency: "IDR",
              price: item.price,
              discount: Math.round(item.price * (item.discount / 100)),
              quantity: 1,
            })),
          },
        })
      }
      setDataPrograms(programs)
    }

    // if not desktop size, concat response data to previous program state
    if (programs && !isDesktop) {
      setDataPrograms((prevProps) => [...prevProps, ...programs])
    }
  }, [programs, isDesktop])

  useEffect(() => {
    if (categorySlugQuery !== "all") {
      let keySelectorCategory: keySelectCategory = "category_name"

      if (language === "ID") {
        keySelectorCategory = "category_name_indo"
      }

      const categoryProgram = allCategories ? allCategories.program_category : []
      const allDataCategory = categoryProgram
        .filter((category) => category.categories.length === 0)
        .map((category) => ({
          label: category[keySelectorCategory] || category.category_name,
          value: category.slug,
          description: category.category_description,
        }))

      const categorySelectedByQuery = allDataCategory.filter(
        (cat) => cat.value === categorySlugQuery,
      )
      setCategorySelected(categorySelectedByQuery)
      setCategoryAllData([{ value: "all", label: t("all_categories") }, ...allDataCategory])
    }

    return () => {
      setCategorySelected([])
      setCategoryAllData([])
    }
  }, [categorySlugQuery, allCategories, language, t])

  useEffect(() => {
    // delete query param `page` when desktop size
    if (!isDesktop) {
      deleteQueryParams("page")
    }
  }, [isDesktop, queries])

  useEffect(() => {
    if (categorySlug !== "all" && dataCategory.length > 0) {
      if (language === "ID") {
        setSectionDescription(dataCategory[0].description_indo || dataCategory[0].description)
      } else {
        setSectionDescription(dataCategory[0].description)
      }
    }
    return () => {
      setSectionDescription("")
    }
  }, [categorySlug, dataCategory, language])

  function resetKeyword() {
    if (!searchValue) {
      deleteQueryParams("keyword")
    }
  }

  const filterSelectOptionCategory = (selectedCategorySlug = "all") => {
    setCategorySelected([{ value: selectedCategorySlug }])
  }

  const applyFilterCategories = (selectedCategory, isMobileFilter = false) => {
    setQueryParams("categories", selectedCategory.toString())
    setQueryParams("page", "1")

    if (!isMobileFilter) {
      deleteQueryParams("keyword")
      deleteQueryParams("date_range")
      deleteQueryParams("min_price")
      deleteQueryParams("max_price")
      deleteQueryParams("sort_by")
      deleteQueryParams("type")
    }

    const queryString = queries.toString()
    const pathname = queryString.length ? `/programs/all?${queryString}` : "/programs/all"
    return router.push(pathname)
  }

  const applyFilterDate = (selectedDate) => {
    setQueryParams("date_range", selectedDate?.toString())
    if (categorySelected.length > 0) {
      if (categorySelected[0].value !== "all") {
        setQueryParams("categories", categorySelected[0].value)
      } else {
        deleteQueryParams("categories")
      }
    }
    const queryString = queries.toString()
    const pathname = queryString.length ? `/programs/all?${queryString}` : "/programs/all"
    router.push(pathname)
  }

  const applyFilters = () => {
    setDataPrograms([])
    if (categorySelected.length > 0) {
      if (categorySelected[0].value !== "all") {
        setQueryParams("categories", categorySelected[0].value)
      } else {
        deleteQueryParams("categories")
      }
    }
    const queryString = queries.toString()
    const pathname = queryString.length ? `/programs/all?${queryString}` : "/programs/all"
    return router.push(pathname)
  }

  const resetFilterDate = () => {
    deleteQueryParams("date_range")
    const queryString = queries.toString()

    const pathname = queryString.length ? `/programs/all?${queryString}` : "/programs/all"

    return router.push(pathname)
  }

  const resetFilter = () => {
    const pathname = "/programs/all"

    return router.push(pathname)
  }

  const navigateSort = (sortBy) => {
    setQueryParams("sort_by", sortBy.toString())
    const queryString = queries.toString()
    const pathname = queryString.length ? `/programs/all?${queryString}` : "/programs/all"
    router.push(pathname)
  }

  const handleSubmitFilterPrices = (minValuePricing, maxValuePricing) => {
    if (minValuePricing) {
      setQueryParams("min_price", minValuePricing?.toString())
    } else {
      deleteQueryParams("min_price")
    }

    if (maxValuePricing) {
      setQueryParams("max_price", maxValuePricing?.toString())
    } else {
      deleteQueryParams("max_price")
    }

    if (categorySelected.length > 0) {
      if (categorySelected[0].value !== "all") {
        setQueryParams("categories", categorySelected[0].value)
      } else {
        deleteQueryParams("categories")
      }
    }
    const queryString = queries.toString()
    const pathname = queryString.length ? `/programs/all?${queryString}` : "/programs/all"
    router.push(pathname)
  }

  const handleResetPrice = () => {
    deleteQueryParams("min_price")
    deleteQueryParams("max_price")
    const queryString = queries.toString()
    const pathname = queryString.length ? `/programs/all?${queryString}` : "/programs/all"
    router.push(pathname)
  }

  const handleNavigateSearch = (ProgramName: any) => {
    setDataPrograms([])

    setQueryParams("keyword", ProgramName)

    const queryString = queries.toString()

    const pathname = queryString.length
      ? `/programs/${categorySlug}?${queryString}`
      : `/programs/${categorySlug}`
    router.push(pathname)
  }

  useEffect(() => {
    if (isOverlayOpen) {
      document.body.className += "body_hide_scroll"
    } else {
      document.body.classList.remove("body_hide_scroll")
    }
  }, [isOverlayOpen])

  const handleChangeCategory = (event: { value: string; label: string }) => {
    setCategorySelected([event])
  }

  let zIndex = ""
  if (isSuggestionSearch) {
    zIndex = "z-40"
  }

  const { pathname } = url.parse(router.asPath)
  const [isSticky, setisSticky] = useState(false)

  const handleScrollToFixedProgramFilter = () => {
    const { scrollY } = window
    if (scrollY > 60) {
      setisSticky(true)
    } else {
      setisSticky(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScrollToFixedProgramFilter)
  }, [])

  useEffect(() => {
    if (!isDesktop) {
      setDataPrograms([])
    }
  }, [router.query?.type])

  return (
    <div>
      <WrapperAnimateRoute isLoading={!isOverlayOpen} type="show">
        <Overlay />
      </WrapperAnimateRoute>
      <NextSeo
        title={`${metaTitle  } | PasarTrainer`}
        description={descriptionName}
        canonical={`${process.env.NEXT_PUBLIC_URL}${pathname}`}
        openGraph={{
          type: "website",
          locale: "en_ID",
          url: `${process.env.NEXT_PUBLIC_URL}${router.asPath}`,
          title: `${metaTitle}`,
          description: descriptionName,
          images: [
            {
              url: openGraphImage,
              alt: `${
                categorySlugQuery === "all"
                  ? "All Category"
                  : capitalize(categorySlugQuery).toString()
              } - PasarTrainer`,
              width: 1200,
              height: 630,
            },
          ],
        }}
      />
      {/* <div className="container mx-auto lg:mt-4">
        <NavParentCategories />
      </div> */}

      {categorySlug !== "all" && (
        <div className="container mx-auto mt-4 sm:mt-12 -mb-4 sm:mb-8 px-4 sm:px-0">
          <div className="flex">
            <Button color="default" variant="text" href="/programs/all">
              <Text size="extra-small" color="primary" weight="semibold">
                {t("all_categories")}
              </Text>
            </Button>
            <Text size="extra-small" color="muted" customClass="px-2">
              /
            </Text>
            <Button color="default" variant="text" href={`/programs/${categorySlug}`}>
              <Text size="extra-small" color="gray" weight="semibold">
                {dataCategory[0]?.name}
              </Text>
            </Button>
          </div>
        </div>
      )}

      <div className={`${isSticky ? "sticky bg-white shadow lg:px-6 mt-0 z-20" : ""} py-6 top-0`}>
        <div
          id="nav-search"
          className={`
            flex lg:py-0 container right-0 mx-auto w-full flex-col justify-around lg:flex-col lg:space-y-0 lg:space-x-0 lg:shadow-lg rounded-lg`}>
          <div className="hidden items-center space-x-10 bg-gray-50 px-6 py-4 lg:flex">
            {isDesktop && (
              <>
                <ProgramsFilter
                  resetCategories={resetCategories}
                  applyFilterCategories={applyFilterCategories}
                  filterSelectOptionCategory={filterSelectOptionCategory}
                  applyFilterDate={applyFilterDate}
                  resetFilterDate={resetFilterDate}
                  handleSubmitFilterPrices={handleSubmitFilterPrices}
                  handleResetPrice={handleResetPrice}
                  applyFilterType={handleChangeFilter}
                />
              </>
            )}
          </div>

          <div
            className={`bottom-0 -top-10 col-span-1 grid w-full rounded-lg bg-white px-4 sm:grid-rows-1 lg:h-20 lg:grid-cols-5 lg:border-t lg:px-0 ${zIndex}`}>
            {/* input search */}
            <div className="relative hidden justify-between lg:col-span-5 lg:flex">
              <div className="flex w-full items-center justify-between">
                <div className="ml-6 text-[#1D4ED8]">
                  <Icon iconName="search" />
                </div>
                <input
                  autoComplete="off"
                  ref={inputSearchRef}
                  value={searchValueArrowKey || searchValue}
                  onChange={(event) => {
                    setSearchValueArrowKey("")
                    setSearchValue(event.target.value)
                    setSuggestionSearch(true)
                    setOverlayOpen(true)
                  }}
                  onKeyDown={(e) => {
                    handleSubmitSearch(e)
                  }}
                  name="search"
                  onFocus={() => {
                    setOverlayOpen(true)
                    setSuggestionSearch(true)
                  }}
                  onBlur={() => {
                    setSuggestionSearch(false)
                    setOverlayOpen(false)
                  }}
                  className="ml-4 w-full border-none py-2 text-sm font-medium leading-5 text-gray-800 outline-none"
                  type="text"
                  placeholder={t("what_do_you_want_to_learn")}
                />
                {(searchValueArrowKey || searchValue) && (
                  <div className="flex items-center w-4 h-10 mx-4">
                    <div className="rounded-full border" onClick={() => {
                      setSearchValueArrowKey("")
                      setSearchValue("")
                      setSuggestionSearch(false)
                      setOverlayOpen(false)
                    }} tabIndex={0} role="button">
                      <Icon iconName="close" styles="w-4 h-4" />
                    </div>
                  </div>
                )}
              </div>
              <div className="flex items-center justify-end">
                <Button
                  onClick={() => {
                    handleSearch(searchValue)
                  }}
                  variant="contained"
                  customClass="h-10 w-12 rounded-full lg:w-px-182 mr-6  lg:rounded-lg"
                  iconRight="union-right"
                  size="medium"
                  leadingText="small"
                  fontWeight="semibold"
                  color="primary">
                  {t("find_training")}
                </Button>
              </div>
              {isSuggestionSearch && searchValue?.length > 0 && (
                <div className="absolute left-9 mt-6 w-full">
                  <SuggestionSearch
                    padding="medium"
                    onFocus={() => {
                      setSuggestionSearch(true)
                      setOverlayOpen(false)
                    }}
                    inputSearchRef={inputSearchRef}
                    searchValue={searchValue.toLocaleLowerCase()}
                    navigateSearch={async (programName) => {
                      handleSearchSuggestion(programName)
                      setSuggestionSearch(false)
                      setOverlayOpen(false)
                    }}
                    onArrowKey={(programName: string) => {
                      setSearchValueArrowKey(programName)
                    }}
                    isSuggestionSearch={isSuggestionSearch}
                  />
                </div>
              )}
            </div>
            {/* input search for mobile */}
            <div className="flex justify-between lg:hidden">
              <div className="relative flex w-full items-center justify-between gap-x-2">
                <div className="flex flex-row items-center border rounded h-10 w-full">
                  <input
                    value={searchValue}
                    onChange={(event) => setSearchValue(event.target.value)}
                    onKeyDown={handleSubmitSearch}
                    onClick={() => setModalSearchOpen(!isModalSearchOpen)}
                    name="search"
                    className="w-full px-2 text-sm font-medium leading-5 text-gray-900 outline-none"
                    type="text"
                    placeholder={t("what_do_you_want_to_learn")}
                  />
                  <div
                    role="button"
                    tabIndex={0}
                    className="bg-primary w-12 h-full flex items-center justify-center rounded-r"
                    onClick={handleSearch}
                  >
                    <Icon iconName="search" width={20} height={20} color="white" />
                  </div>
                </div>
                <div
                  role="button"
                  tabIndex={0}
                  className={`bg-white w-12 h-full flex items-center justify-center border ${totalFilter > 0 ? "border-primary" : "border-gray-200"} rounded`}
                  onClick={() => {
                    if (!isModalFilterOpen) {
                        document.body.style.overflow = "hidden"
                      } else {
                        document.body.style.overflow = "scroll"
                      }
                      setModalFilterOpen(!isModalFilterOpen)
                    }
                  }
                >
                  <Icon iconName="adjustments" width={22} height={22} color={totalFilter > 0 ? "#286DCC" : "#000"} type="stroke" />
                </div>
                {totalFilter > 0 && (
                  <div className="absolute top-[-8px] right-5 h-4 w-4 bg-primary rounded-full flex items-center justify-center text-white">
                    <span className="text-[10px] font-medium">{totalFilter}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      
      {isDesktop ?
        <div className="hidden sm:block">
          {(isLoading || !data) ? 
            <div className="container mx-auto relative px-4 sm:px-0 -mt-8 sm:mt-0 sm:pt-10">
              <SkeletonProgramsAll />
            </div>
            :
            <CategoryListDesktop 
              isLoading={(isLoading || !data)}
              categorySlug={categorySlug}
              t={t}
              sectionName={sectionName}
              sectionDescription={sectionDescription}
              activeSortLabel={activeSortLabel}
              sortList={sortList}
              handleChangeSort={handleChangeSort}
              handleChangePage={handleChangePage}
              dataPrograms={dataPrograms}
              totalProgram={totalProgram}
              page={page}
              />
          }
        </div>
        :
        <div className="block sm:hidden">
          <CategoryListMobile 
            isLoading={(isValidating || !data)}
            categorySlug={categorySlug}
            t={t}
            sectionName={sectionName}
            sectionDescription={sectionDescription}
            activeSortLabel={activeSortLabel}
            sortList={sortList}
            handleChangeSort={handleChangeSort}
            dataPrograms={dataPrograms}
            totalProgram={totalProgram}
            selectedPage={selectedPage}
            router={router}
            setProgramPublishedUrl={setProgramPublishedUrl}
            />
        </div>
      }
      

      {/* modal search open */}
      <ModalSearch
        isOpen={isModalSearchOpen}
        handleNavigate={(programName) => {
          handleNavigateSearch(programName)
          setModalSearchOpen(!isModalSearchOpen)
        }}
        onChange={(programName) => setSearchValue(programName)}
        openModalAllFilters={() => setModalFilterOpen(!isModalFilterOpen)}
        onClose={() => setModalSearchOpen(!isModalSearchOpen)}
        totalFilter={totalFilter}
      />

      <ModalAllFilters
        navigateClassTypes={(event) => {
          handleChangeFilter(event)
          setModalFilterOpen(!isModalFilterOpen)
          document.body.style.overflow = "scroll"
        }}
        applyFilters={applyFilters}
        resetKeyword={resetKeyword}
        navigateSort={navigateSort}
        isOpen={isModalFilterOpen}
        navigateCategories={(selectedCategory) => applyFilterCategories(selectedCategory, true)}
        onClose={() => {
          document.body.style.overflow = "scroll"
          setModalFilterOpen(!isModalFilterOpen)
        }}
        navigatePrice={handleSubmitFilterPrices}
        navigateDate={applyFilterDate}
        resetFilter={() => {
          setModalFilterOpen(false)
          resetFilter()
          document.body.style.overflow = "scroll"
        }}
        filterData={filterData}
        categorySelectType="single"
        onSelectCategory={(selectedCategorySlug: string, types, selectedDates, minValuePrice, maxValuePrice, sortBy) => {
          setDataPrograms([])
          setModalFilterOpen(!isModalFilterOpen)
          handleResetOnChangeFilter()
          // if hide types more than 0
          if (types.length > 0) {
            setQueryParams("type", types.toString())
          }
          if (types.length <= 0) {
            deleteQueryParams("type")
          }

          // if date Exist
          if (selectedDates) {
            setQueryParams("date_range", selectedDates?.toString())
          }

          // if min price exist
          if (minValuePrice) {
            setQueryParams("min_price", minValuePrice?.toString())
          } else {
            deleteQueryParams("min_price")
          }

          // if max price exist
          if (maxValuePrice) {
            setQueryParams("max_price", maxValuePrice?.toString())
          } else {
            deleteQueryParams("max_price")
          }

          // if sort by exist
          if (sortBy) {
            setQueryParams("sort_by", sortBy.toString())
          }

          document.body.style.overflow = "scroll"
          setQueryParams("categories", selectedCategorySlug)
          const queryString = queries.toString()
          const pathQueryString = queryString.length ? `/programs/all?${queryString}` : "/programs/all"
          return router.push(pathQueryString)
        }}
      />
      {/* modal search open */}
    </div>
  )
}

Category.navbarFixed = false
export default Category
