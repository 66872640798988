import Text from "lib/components/Text"
import { useContext, useEffect, useState } from "react"
import Button from "lib/components/Button"
import useQueryParams from "hooks/useQueryParams"
import { LanguageContext } from "context/LanguageContext"
import InputPrice from "lib/components/InputPrice"

interface PropsCardPriceFilter {
  onChangeMinValue?: (value: number) => void
  onChangeMaxValue?: (value: number) => void
  handleSubmitFilterPrices?: (value: number, valueSec: number) => void
  handleResetPrice?: () => void
}
function CardPriceFilter({
  handleSubmitFilterPrices,
  handleResetPrice,
}: PropsCardPriceFilter) {
  const { useTranslation: t } = useContext(LanguageContext)
  const [minValuePricing, setMinValuePricing] = useState(null)
  const [maxValuePricing, setMaxValuePricing] = useState(null)
  const queries = useQueryParams()

  useEffect(() => {
    if (queries?.get("max_price") || queries?.get("min_price")) {
      const maxPrice = queries?.get("max_price") ? parseInt(queries?.get("max_price"), 10) : 0
      const minPrice = queries?.get("min_price") ? parseInt(queries?.get("min_price"), 10) : 0
      setMaxValuePricing(maxPrice)
      setMinValuePricing(minPrice)
    } else {
      setMaxValuePricing("")
      setMinValuePricing("")
    }
  }, [queries?.get("max_price"), queries?.get("min_price")])

  function validateInput() {
    let isReadyToApply = true
    const minPriceCheck = minValuePricing ? parseInt(minValuePricing, 10) : 0
    const maxPriceCheck = maxValuePricing ? parseInt(maxValuePricing, 10) : 0

    const minAndMaxMoreThanZero = () => {
      if (minPriceCheck > 0 && maxPriceCheck > 0) {
        return true
      }
      return false
    }

    const minPriceLessThanMaxPrice = () => {
      if (minPriceCheck < maxPriceCheck) {
        return true
      }
      return false
    }

    const minPriceMoreAndMaxLess = () => {
      if (minPriceCheck > 0 && maxPriceCheck <= 0) {
        return true
      }
      return false
    }

    const minPriceLessMaxPriceMoreThanZero = () => {
      if (minPriceCheck <= 0 && maxPriceCheck > 0) {
        return true
      }
      return false
    }

    if (minAndMaxMoreThanZero()) {
      if (minPriceLessThanMaxPrice()) {
        isReadyToApply = false
      }
    }

    if (minPriceMoreAndMaxLess()) {
      isReadyToApply = false
    }
    if (minPriceLessMaxPriceMoreThanZero()) {
      isReadyToApply = false
    }

    return isReadyToApply
  }
  return (
    <section className="top-0 left-0 right-0 bottom-0 flex h-full w-full overflow-y-auto bg-opacity-80 lg:p-4">
      <div className="flex w-full flex-col justify-between rounded-md bg-white lg:border lg:shadow-lg">
        {/* serach categories */}
        <div className="px-6 pt-6  lg:block hidden">
          <h1 className="font-bold text-sm leading-5">{t("price")}</h1>
        </div>
        {/* content */}
        <div className="flex flex-row space-x-4  overflow-hidden pt-2 lg:px-6">
          <div className="mr-auto flex h-11 w-full items-center rounded-lg border">
            <InputPrice 
              name="min_value"
              placeholder="0"
              value={minValuePricing}
              onChange={(value) => setMinValuePricing(value)}
              noBorder
              />
            <div className="mr-3 text-gray-600 hover:text-gray-900">
              <Text size="small" color="muted" weight="medium">
                Min.
              </Text>
            </div>
          </div>
          <div className="mr-auto flex h-11 w-full items-center rounded-lg border">
            <InputPrice 
              name="max_value"
              placeholder="0"
              value={maxValuePricing}
              onChange={(value) => setMaxValuePricing(value)}
              noBorder
              />
            <div className="mr-3 text-gray-600 hover:text-gray-900">
              <Text size="small" color="muted" weight="medium">
                Max.
              </Text>
            </div>
          </div>
        </div>
        <div className="lg:px-6 mt-6 w-full">
          <input
            type="range"
            min="0"
            max="1000000"
            value={maxValuePricing}
            defaultValue={maxValuePricing}
            step="5000"
            className="appearance-none w-full h-2 rounded-full bg-gray-100 outline-none cursor-pointer"
            list="tickmarks"
            onChange={(event) => {
              const value = parseInt(event.target.value, 10)
              setMaxValuePricing(value)
            }}
          />
        </div>
        {/* footer */}
        <div className="mt-6 hidden flex-row items-center border-t px-6 py-4 lg:flex">
          <Button
            variant="text"
            color="white"
            colorText="muted"
            fontWeight="semibold"
            disabled={validateInput()}
            onClick={() => {
              setMaxValuePricing("")
              setMinValuePricing("")
              if (queries?.get("max_price") || queries?.get("min_price")) {
                handleResetPrice()
              }
            }}
            customClass="w-10">
            {t("reset")}
          </Button>
          <div className="flex w-full items-end justify-end">
            <Button
              variant="contained"
              color="primary"
              rounded="large"
              disabled={validateInput()}
              onClick={() => handleSubmitFilterPrices(minValuePricing, maxValuePricing)}
              customClass="flex flex-wrap justify-center w-28 px-4 py-2.5 hover:bg-blue-600 font-medium text-base text-white border border-blue-500 rounded-md shadow-button">
              {t("apply")}
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CardPriceFilter
