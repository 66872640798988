import { forwardRef, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import Text from "lib/components/Text"

export interface InputPriceProps {
  inline?: boolean;
  label?: string;
  placeholder?: string;
  onChange?: (value) => void
  value?: string | number;
  name: string;
  disabled?: boolean;
  maxLength?: number;
  success?: boolean;
  error?: boolean;
  message?: string;
  onlyBorderBottom?: boolean
  noBorder?: boolean
  labelColor?: "gray" | "dark"
}

const InputPrice = forwardRef<HTMLInputElement, InputPriceProps>(({
  inline, label, placeholder, onChange, value, name, disabled, maxLength, success, error, message, onlyBorderBottom = false, labelColor = "gray", noBorder,
}: InputPriceProps, InputPriceRef) => {
  InputPrice.displayName = "InputPrice"

  let borderColor = "border-[#D1D5DB]"
  let borderFocus = "border-gray-400"
  let iconType = ""
  let iconColor = "gray"
  let inlineStyle = "flex-col"
  let labelStyle = "mb-2"
  let borderStyle = "border"
  let labelValue = <div />

  if (onlyBorderBottom) {
    borderStyle = "border-b"
  }

  if (noBorder) {
    borderStyle = "border-0"
  }

  if (inline) {
    inlineStyle = "flex-row items-center"
    labelStyle = "mr-2"
  }

  if (success) {
    iconType = "check"
    iconColor = "green"
  }

  if (error) {
    borderColor = "border-red-500"
    borderFocus = "border-red-500"
    iconType = "error"
    iconColor = "red"
  }

  if (label) {
    if (label.includes("*")) {
      labelValue = (
        <div className="flex gap-x-1">
          <Text type="small-body" color={labelColor}>
            {label.replace(" *", "")}
          </Text>
          <Text weight="bold" type="small-body" color="danger">
            *
          </Text>
        </div>
      )
    } else {
      labelValue = (
        <Text type="small-body" color={labelColor}>
          {label}
        </Text>
      )
    }
  }

  let iconStyle = "right-2 top-2"
  let paddingRight = "pr-4"

  if (iconType) {
    iconStyle = "right-4 top-2"
    paddingRight = "pr-12"
  }

  return (
    <div className={`flex ${inlineStyle}`}>
      {label
        && (
          <div className={labelStyle}>
            {labelValue}
          </div>
        )}
      <div className="relative flex-1">
        <CurrencyInput
          className={`w-full rounded ${borderStyle} ${borderColor} ${disabled ? "bg-gray-200" : "bg-white"} py-1.5 px-4 focus:outline-none placeholder:text-[#808080] placeholder:opacity-100 placeholder:text-sm`}
          disabled={disabled}
          ref={InputPriceRef}
          name={name}
          value={value}
          placeholder={placeholder}
          onValueChange={(eventValue) => onChange(eventValue)}
          decimalSeparator="," 
          groupSeparator="."
        />
      </div>
      {error
        && (
          <div className="mt-1">
            <Text type="small-body" color="danger">{message}</Text>
          </div>
        )}
    </div>
  )
})

export default InputPrice
