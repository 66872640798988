import InfiniteScroll from "react-infinite-scroller"

import ProgramList from "@components/List/ProgramList"
import SkeletonProgramsAll from "@components/Skeleton/SkeletonProgramsAll"
import Dropdown from "lib/components/Dropdown"
import Text from "lib/components/Text"
import LoadingComponent from "lib/components/Loading"

interface CategoryListMobileProps {
  isLoading: boolean
  categorySlug: string,
  t,
  sectionName: string,
  sectionDescription: string,
  activeSortLabel: string,
  sortList,
  handleChangeSort,
  dataPrograms,
  totalProgram: number,
  selectedPage: number,
  router,
  setProgramPublishedUrl,
}

function CategoryListMobile({
  isLoading,
  categorySlug,
  t,
  sectionName,
  sectionDescription,
  activeSortLabel,
  sortList,
  handleChangeSort,
  dataPrograms,
  totalProgram,
  selectedPage,
  router,
  setProgramPublishedUrl,
}: CategoryListMobileProps) {

  return (
    <div className="relative px-4 sm:px-0 -mt-8 sm:mt-0 sm:pt-10">
      <div className="container mx-auto">
        {isLoading ? (
          <SkeletonProgramsAll />
        ) : (
          <>
            <div className="my-6 grid grid-cols-1 items-center sm:grid-cols-12 lg:my-4">
              <div className="col-span-7 justify-self-start">
                <h1 className="xl:text-xl 2xl:text-2xl font-semibold leading-8 text-gray-900">
                  {categorySlug === "all"
                    ? t("all_training_from_selected_category")
                    : sectionName}
                </h1>
                <Text size="small" customClass="leading-5" weight="medium" color="muted">
                  {categorySlug === "all" ? t("we_have_many_training_here") : sectionDescription}
                </Text>
              </div>
              <div className="col-span-5 hidden  z-10 flex-col items-center justify-end gap-2 md:flex-row lg:flex">
                <Dropdown
                  title={
                    <div className="flex flex-row items-center gap-2">
                      <Text
                        size="small"
                        customClass="leading-5"
                        weight="medium"
                        color="gray-darkest">
                        {`  ${t("sort_by")} : ${t(`${activeSortLabel}`)}`}
                      </Text>
                    </div>
                  }
                  iconColor="black">
                  <ul className="list-none overflow-hidden rounded px-4 py-2">
                    {sortList
                      .filter((item) => !!item.show)
                      .map((item) => (
                        <li
                          key={item.label}
                          className="cursor-pointer py-2"
                          data-sort={item.key}
                          onClick={handleChangeSort}>
                          <Text>{t(item.label)}</Text>
                        </li>
                      ))}
                  </ul>
                </Dropdown>
              </div>
            </div>
            <div className="mt-10">
              <InfiniteScroll
                pageStart={selectedPage}
                loadMore={() => {
                  const urlQuery = router.query || {}
                  if (dataPrograms.length > 0) {
                    Object.assign(urlQuery, { page: selectedPage + 1 })
                    const dataUrl = `?${Object.keys(urlQuery)
                      .map((key) => `${key}=${urlQuery[key]}`)
                      .join("&")}`
                    setProgramPublishedUrl(dataUrl)
                  }
                }}
                hasMore={dataPrograms.length < totalProgram}
                loader={<LoadingComponent size="extra-small" withoutMinHeight key={0} />}>
                <ProgramList
                  sectionName={sectionName}
                  programs={dataPrograms}
                  loading={isLoading}
                />
              </InfiniteScroll>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default CategoryListMobile
